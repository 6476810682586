<template>
    <div>
        <div v-for="(faq, index) in faqItems" :key="index">
            <div class="faq">
                <p class="question" @click="toggleAnswer(faq)">
                    {{ faq.q }}
                    <span class="toggleIcon">
                        {{ faq.isOpen ? "-" : "＋" }}
                    </span>
                </p>
                <p class="answer" :ref="'answer' + faq.id" :id="'answer' + faq.id" v-html="faq.a"></p>


            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            password1: "",
            faqItems: [
                {
                    id: 1,
                    q: "Who is Your Insurance?",
                    a: `<p>Your Insurance is an insurtech offering technology,
                        advisory, and marketing solutions to the insurance and
                        finance sectors, including brokers, lenders and dealers.</p>
                        <p>
                        Our focus is on designing innovative technology
                        solutions to elevate business value and foster growth for
                        our partners..</p>`,
                    isOpen: true
                },
                {
                    id: 2,
                    q: "Who is Insurance Advisernet?",
                    a: `<p>Insurance Advisernet was founded in 1996 with a clear
and powerful aim: to provide high-quality risk
management advice that business owners can trust.</p>

<p>Today, it’s one of the largest and most respected
General Insurance broking operations in Australia and
New Zealand, with an ever-growing network of over 270
insurance advice practices across 150 locations
transacting per annum over $1.7 invoiced premium,
across 300,000 policies.</p>`,
                    isOpen: false
                },
                {
                    id: 3,
                    q: "How the Your Insurance portal works",
                    a: `<p>The Your Insurance Portal serves as a comprehensive hub for all
your insurance requirements, providing our partners with seamless
access to diverse insurance markets and products. Our
cutting-edge technology offers immediate solutions with
comprehensive insurance options for market comparison. There
are two options:.
</p>
<p>
<b>Virtual Insurance Broking Service (VIBS):</b> Our Virtual Insurance
Broking Service allows you to source multiple quotes from the
market, providing your clients with multiple options to choose from.
This service is provided by Insurance Advisernet.
</p>
<p>
<b>Light Commercial Motor:</b> Our portal provides real time quoting and
immediate policy binding for Light Commercial Motor vehicles of up
to 4.5 tonnes. Vehicles in the light commercial space include,
Passenger vehicles, Utes and Vans etc.
</p>
`,
                    isOpen: false
                }
            ]
        };
    },
    methods: {
        toggleAnswer(_faq) {
            console.log(_faq);

            if (_faq.isOpen) {
                _faq.isOpen = false;
                this.expand("answer" + _faq.id);
            } else {
                this.collapse("answer" + _faq.id);
                _faq.isOpen = true;
            }

           

            
        },
        collapse(refItem) {
            // select the answer element

            const answer = this.$refs[refItem][0];

            // set its height to its normal scroll height to expand it fully
            answer.style.height = 0;
        },
        expand(refItem) {
            // select answer element


            const answer = this.$refs[refItem][0];

            // set its height to its normal scroll height to expand it fully
            answer.style.height = answer.scrollHeight + "px";
        }
    },
    mounted() {
        this.expand("answer1");
    },

    components: {
        //TextFooter
    }
};
</script>

<style lang="scss" scoped>
/* style the FAQ section */
.question {
    background: #ff4438;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0px 4px 0px 0 #88888855;
    padding: 10px 0;
    transition: transform 0.2s;
    position: relative;
    padding-left: 20px;
    color: white;
}

.question:hover {
    background: red;
}



/* styles when the question is clicked */
.question:active {
    transform: translateY(4px);
    box-shadow: none;
}

.answer {
    transition: 0.25s;
    /* smooth slide-in */
    height: 0;
    /* starts collapsed */
    overflow: hidden;
    line-height: 1.5;
    margin-bottom: 0px !important;
}



/* style the toggleIcon */
.toggleIcon {
    font-size: 1.5em;
    font-weight: bold;
    position: absolute;
    top: 18px;
    right: 20px;
    display: inline-block;
    line-height: 0.5;
    color: white;
}
</style>