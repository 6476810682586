import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import RequestPasswordReset from "../views/RequestPasswordReset.vue";
import ResetPassword from "../views/resetPassword.vue";
import SetPassword from "../views/SetPassword.vue";
import FeedbackSurvey from "../views/FeedbackSurvey.vue";



import AcceptOffer from "../views/AcceptOffer.vue";
import ContinueQuote from "../views/lightCommercial/continueQuote.vue";

import DownloadFile from "../views/DownloadFile";
import DownloadClaimFile from "../views/DownloadClaimFile";

import CertificateOfCurrency from "../views/CertificateOfCurrency.vue";

import TriggerClaimCompleteEmail from "../views/TriggerClaimCompleteEmail.vue";

import DashboardLayout from "../layouts/DashboardLayout.vue";
import AuthLayout from "../layouts/AuthLayout.vue";

import Dashboard from "../views/dashboard/Dashboard.vue"

// Admin
import EmailTemplates from "../views/admin/EmailTemplates.vue";
import ContentTemplates from "../views/admin/ContentTemplates.vue";
import Brokers from "../views/admin/Brokers.vue";
import Customers from "../views/admin/Customers.vue";
import BrokerOrgs from "../views/admin/BrokerOrgs.vue";
import InsuranceBrokers from "../views/admin/InsuranceBrokers.vue";

// multiple product
import CreateQuote from "../views/products/createQuote.vue";
import Quotes from "../views/products/Quotes.vue"
import Policies from "../views/products/Policies.vue"
import EditQuote from "../views/products/EditQuote.vue"
import ProductContinueQuote from "../views/products/ContinueQuote.vue";
import QuotesAndPolicies from "../views/QuotesAndPolicies.vue";
import ViewQuote from "../views/ViewQuote.vue";
import VIBSQuoteEmailed from "../views/products/peQuoteEmailed.vue"

import SupportPage from "../views/SupportPage.vue";

import CreateLCQuote from "../views/lightCommercial/createLCQuote.vue";
// import CreateQuoteLightVehicleStep1 from "../views/lightCommercial/createQuoteStep1.vue";
import lcQuoteEmailed from "../views/lightCommercial/lcQuoteEmailed.vue";
import QuotesLightCommercial from "../views/QuotesLightCommercial.vue";
import PoliciesLightCommercial from "../views/PoliciesLightCommercial.vue";

import fleetQuoteEmailed from "../views/lightCommercial/fleetQuoteEmailed.vue";
import ClaimEmailed from "../views/lightCommercial/lcClaimEmailed.vue";
// Fleet
import CreateLCFleetQuote from "../views/lightCommercial/fleet/createLCFleetQuote.vue";

// Claims
import ClaimsLightCommercial from "../views/lightCommercial/ClaimsLightCommercial.vue";
import SubmitClaim from "../views/lightCommercial/SubmitClaim.vue";

import editQuote from "../views/lightCommercial/editQuote.vue";
import manage from "../views/lightCommercial/manage.vue";

import OfferSent from "../views/OfferSent";

import BrokerReports from "../views/reports/BrokerReports.vue";
import OrgReports from "../views/reports/OrgReports.vue";
import YourInsuranceReports from "../views/reports/YourInsuranceReports.vue";

// Fleet Management
import FleetManagement from "../views/fleetManagement/FleetManagementServices.vue";
import CallBackRequested from "../views/fleetManagement/CallBackRequested.vue";

// Ride Share
import CreateRSQuote from "../views/rideshare/CreateRSQuote.vue";
import { default as QuotesRideShare } from "../views/rideshare/Quotes.vue";
import { default as PoliciesRideShare } from "../views/rideshare/Policies.vue";
import { default as ClaimsRideShare } from "../views/rideshare/Claims.vue";
import { default as SubmitClaimRideShare } from "../views/rideshare/SubmitClaim.vue";
import { default as ClaimSentRideShare } from "../views/rideshare/ClaimSent.vue";
import { default as ContinueQuoteRideShare } from "../views/rideshare/ContinueQuote.vue";
import { default as EditQuoteRideShare } from "../views/rideshare/EditQuote.vue";
import { default as QuoteEmailedRideShare } from "../views/rideshare/QuoteEmailed.vue";
import CreateRSFleetQuote from "../views/rideshare/fleet/createRSFleetQuote.vue";
import fleetRSQuoteEmailed from "../views/rideshare/fleet/fleetQuoteEmailed.vue";

Vue.use(VueRouter);

const unAauthenticatedPages = {
  path: "/",
  component: AuthLayout,
  name: "Unauthenticated Pages",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "createQuote",
      name: "Create Quote",
      component: CreateQuote
    },
    {
      path: "/requestPasswordReset",
      name: "Request Password Reset",
      component: RequestPasswordReset
    },
    {
      path: "/feedbackSurvey",
      name: "Feedback Survey",
      component: FeedbackSurvey
    },
    {
      path: "/resetPassword",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/setPassword",
      name: "Set Password",
      component: SetPassword
    },
    {
      path: "/lightCommercial/continueQuote/:id",
      name: "Continue Quote - Light Commercial",
      component: ContinueQuote
    },
    {
      path: "/rideShare/continueQuote/:id",
      name: "Continue Quote - Rideshare",
      component: ContinueQuoteRideShare
    },
    {
      path: "/downloadFile/:id",
      name: "Download File",
      component: DownloadFile
    },
    {
      path: "/downloadClaimFile/:id",
      name: "Download Claim File",
      component: DownloadClaimFile
    },
    {
      path: "/acceptOffer/:id",
      name: "Accept Offer",
      component: AcceptOffer
    },
    {
      path: "/certificateOfCurrency/:id",
      name: "Certificate of Currency",
      component: CertificateOfCurrency
    },
    {
      path: "/triggerclaimcompleteemail/:policy_id/:guid",
      name: "trigger Claim Completion Email",
      component: TriggerClaimCompleteEmail
    },
    {
      path: "/products/:productName/continueQuote/:userType/:id", // userType: raa (Risk Advisory Members) || yiCustomer
      name: "Continue Quote - Plant & Equiqment",
      component: ProductContinueQuote
    },
    // { path: '*', component: NotFound }
  ]
};

const adminPages = {
  path: "/admin",
  component: DashboardLayout,
  redirect: "/admin/brokers",
  name: "Admin",
  children: [
    {
      path: "brokers",
      name: "Brokers",
      component: Brokers
    },
    {
      path: "brokerOrgs",
      name: "Broker Orgs",
      component: BrokerOrgs
    },
    {
      path: "insuranceBrokers",
      name: "InsuranceBrokers",
      component: InsuranceBrokers
    },
    {
      path: "customers",
      name: "Customers",
      component: Customers
    },
    {
      path: "emailTemplates",
      name: "Email Templates",

      component: EmailTemplates
    },
    {
      path: "contentTemplates",
      name: "Content Templates",

      component: ContentTemplates
    }
    // {
    //   path: "createQuote",
    //   name: "Create Quote",
    //   component: CreateQuote
    // },

    // {
    //   path: 'components',
    //   name: 'Form components',
    //   component:  FormComponents
    // },
    // {
    //   path: 'validation',
    //   name: 'Form validation',
    //   component:  FormValidation
    // }
  ]
};

const pages = {
  path: "/",
  component: DashboardLayout,
  name: "Pages",
  children: [
    {
      path: "dashboard",
      name: "Dashboard",
      component: Dashboard
    },
    {
      path: "support",
      name: "Support",
      component: SupportPage
    },
    {
      path: "createQuote",
      name: "Create Quote",
      component: CreateQuote
    },
    {
      path: "quotesAndPolicies",
      name: "Quotes and Policies",
      component: QuotesAndPolicies
    },
    {
      path: "quotes/:id",
      name: "View / Edit Quote",
      component: ViewQuote
    },
    {
      path: "plant-and-equipment/offerSent",
      name: "Offer Sent",
      component: OfferSent
    }
  ]
};

const reports = {
  path: "/reports",
  component: DashboardLayout,
  name: "Reports",
  children: [
    {
      path: "brokerReports",
      name: "Broker Reports",
      component: BrokerReports
    },
    {
      path: "orgReports",
      name: "Org Reports",
      component: OrgReports
    },
    {
      path: "yourInsuranceReports",
      name: "Reports",
      component: YourInsuranceReports
    }
  ]
};

// const support = {
//   path: "/support",
//   component: DashboardLayout,
//   name: "Support",
//   children: [
//     {
//       path: "cheatSheat",
//       name: "Cheat Sheet",
//       component: Support
//     }
//   ]
// }

const lightVehicle = {
  path: "/lightCommercial",
  component: DashboardLayout,
  name: "Light Commercial",
  children: [
    {
      path: "createQuote",
      name: "Create Quote",
      component: CreateLCQuote
    },
    {
      path: "quoteEmailed",
      name: "Light Commercial - Quote Emailed",
      component: lcQuoteEmailed
    },
    /*
    {
      path: "createQuote/step1",
      name: "Customer Search - Light Commercial",
      component: CreateQuoteLightVehicleStep1
    },
    {
      path: "createQuote/step2",
      name: "Create Quote - Light Commercial",
      component: CreateQuoteLightVehicleStep2
    },
    */

    {
      path: "quotes",
      name: "Light Commercial Quotes",
      component: QuotesLightCommercial
    },
    {
      path: "policies",
      name: "Light Commercial Policies",
      component: PoliciesLightCommercial
    },
    {
      path: "editquote/:id",
      name: "Edit Light Commercial Quotes",
      component: editQuote
    },
    {
      path: "manage",
      name: "Manage Edit Light Commercial Quotes",
      component: manage
    },

    // Fleet
    {
      path: "fleet",
      name: "Fleet",
      component: CreateLCFleetQuote
    },
    {
      path: "fleetQuoteEmailed",
      name: "Light Commercial - Fleet Quote Emailed",
      component: fleetQuoteEmailed
    },

    // Claims
    {
      path: "claims",
      name: "Claims",
      component: ClaimsLightCommercial
    },
    {
      path: "submitClaim",
      name: "Submit Claim",
      component: SubmitClaim
    },
    {
      path: "claimSent",
      name: "Claim Sent",
      component: ClaimEmailed
    }
  ]
};

const rideShare = {
  path: "/rideShare",
  component: DashboardLayout,
  name: "Rideshare",
  children: [
    {
      path: "createQuote",
      name: "Create Quote",
      component: CreateRSQuote
    },
    {
      path: "quoteEmailed",
      name: "Rideshare - Quote Emailed",
      component: QuoteEmailedRideShare
    },

    {
      path: "quotes",
      name: "Rideshare Quotes",
      component: QuotesRideShare
    },
    {
      path: "policies",
      name: "Rideshare Policies",
      component: PoliciesRideShare
    },
    {
      path: "editquote/:id",
      name: "Edit Rideshare Quotes",
      component: EditQuoteRideShare
    },
    // {
    //   path: "manage",
    //   name: "Manage Edit Light Commercial Quotes",
    //   component: manage
    // },

    // Fleet
    {
      path: "fleet",
      name: "Fleet",
      component: CreateRSFleetQuote
    },
    {
      path: "fleetRSQuoteEmailed",
      name: "RideShare - Fleet Quote Emailed",
      component: fleetRSQuoteEmailed
    },

    // // Claims
    {
      path: "claims",
      name: "Claims",
      component: ClaimsRideShare
    },
    {
      path: "submitClaim",
      name: "Submit Claim",
      component: SubmitClaimRideShare
    },
    {
      path: "claimSent",
      name: "Claim Sent",
      component: ClaimSentRideShare
    }
  ]
};

const plantEquipment = {
  path: "/products",
  component: DashboardLayout,
  name: "Plant & Equipment",
  children: [
    {
      path: ":productName/createQuote",
      name: "Create Quote",
      component: CreateQuote
    },
    {
      path: ":productName/quotes",
      name: "Quotes",
      component: Quotes
    },
    {
      path: ":productName/policies",
      name: "Policies",
      component: Policies
    },
    {
      path: ":productName/editquote/:id",
      name: "Edit Quotes",
      component: EditQuote
    },
    {
      path: ":productName/quoteEmailed",
      name: "VIBS Quote Emailed",
      component: VIBSQuoteEmailed,
    },
  ]
};

const fleetManagement = {
  path: "/fleetManagement",
  component: DashboardLayout,
  name: "Fleet Management",
  children: [
    {
      path: "managementServices",
      name: "Fleet Management Services",
      component: FleetManagement
    },
    {
      path: "callBackRequested",
      name: "Call Back Requested",
      component: CallBackRequested
    }
  ]
};

const routes = [
  {
    path: "/",
    redirect: "dashboard"
  },
  pages,
  
  unAauthenticatedPages,
  adminPages,
  reports,
  lightVehicle,
  rideShare,
  fleetManagement,
  plantEquipment,
  //support,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
