<template>
  <router-view></router-view>
</template>

<script>
import { authMethods } from "./helpers/auth";

var idleTime = 0;

export default {
  methods: {
    isLoggedIn() {
      return authMethods.isLoggedIn();
    },
    logOut() {
      sessionStorage.clear();
      localStorage.removeItem("rmt");
      this.$router.push("/login");
      return false;
    },
    timerIncrement() {
      if (sessionStorage.getItem("pex") != null) {
        idleTime = idleTime + 1;

        if (idleTime > 29) {
          // 30 minutes
          sessionStorage.clear();
          this.$router.push("/login?autologout=true");
          this.$toasted.error(
            "You were logged out due to 30 mins of inactivity.",
            {
              position: "bottom-center"
            }
          );
        }
      } else {
        idleTime = 0;
      }
    }
  },
  mounted() {
    var idleInterval = setInterval(this.timerIncrement, 60000);
    document.addEventListener("keydown", () => {
      idleTime = 0;
    });

    document.addEventListener("mousemove", () => {
      idleTime = 0;
    });
  }
};
</script>

<style>
.form-control:disabled,
.form-control[readonly] {
  color: black !important;
}

.btn-success {
  background-color: #ff4438 !important;
  border-color: #ff4438 !important;
}

.text-primary {
  color: #ff4438 !important;
}

a.text-primary {
  color: #ff4438 !important;
}

.page-item.active button {
  background-color: #ff4438 !important;
  border-color: #ff4438 !important;
}

.router-link-exact-active {
  font-weight: bold !important;
  color: #ff4438 !important;
}

div.el-table .el-table__row .cell {
  color: black;
}

.el-table .el-table__header-wrapper thead th .cell {
  color: black;
}

.btn-uBind {
  font-weight: 400 !important;
}

div.modal.modal-support .modal-dialog {
  width: 80% !important;
  max-width: 80% !important;
  height: 80% !important;
  max-height: 80% !important;
}

div.modal.modal-support .modal-content {

  height: 80% !important;
  max-height: 80% !important;
  overflow-y: scroll;
}
</style>
