<template>
  <div>
  <base-nav container-classes="container-fluid" class="navbar-top border-bottom navbar-expand"
    :class="{ 'navbar-dark': type === 'default' }">
    <!-- Search form -->
    <!-- <b-form
      class="navbar-search form-inline mr-sm-3"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light'
      }"
      id="navbar-search-main"
    >
      <b-form-group class="mb-0">
        <b-input-group class="input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <b-form-input placeholder="Search" type="text"> </b-form-input>
        </b-input-group>
      </b-form-group>
      <button
        type="button"
        class="close"
        data-action="search-close"
        data-target="#navbar-search-main"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </b-form> -->
    <!-- Navbar links -->
    <b-navbar-nav class=" align-items-center ml-md-auto">
      <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler" :class="{
          active: $sidebar.showSidebar,
          'sidenav-toggler-dark': type === 'default',
          'sidenav-toggler-light': type === 'light'
        }" @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </b-navbar-nav>
    <b-navbar-nav style="width:100%">
      <div v-if="getNotification.notification == 'full-outage'"
        style="float: left;background-color: red; padding: 20px; color: white; border-radius: 20px; font-weight:900; font-size: 14pt">
        {{ getNotification.message }}</div>
      <div v-if="getNotification.notification == 'part-outage'"
        style="float: left;background-color: orange; padding: 20px; color: white; border-radius: 20px; font-weight:900; font-size: 14pt">
        {{ getNotification.message }}</div>
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0" style="width:5%">
      <!-- <i class="ni ni-support-16" style="color: white; font-size: 20pt;"></i> -->
      
      <div title="Help me with this page" @click="clickSupport"  style="color: white; font-size: 18pt; font-weight: bolder; border: 2px solid white; border-radius: 60px; padding: 0px 12px 0px 12px; cursor: pointer;">
        ?
      </div>
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0" style="width:15%">

      <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <b-media no-body class="align-items-center">
            <b-avatar variant="secondary" :text="getBrokerInitials()"></b-avatar>
            <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{
                getBrokerName()
              }}</span>
            </b-media-body>
          </b-media>
        </a>

        <template>
          <b-dropdown-header class="noti-title">
            <h6 class="text-overflow m-0">Welcome, {{ getBrokerName() }}!</h6>
          </b-dropdown-header>
          <b-dropdown-item href="mailto:support@yourinsurancehq.com.au">
            <i class="ni ni-support-16"></i>
            <span>Support</span>
          </b-dropdown-item>
          <b-dropdown-item>
            <i class="ni ni-app"></i><span>v{{ appVersion }}</span>
          </b-dropdown-item>
          <div class="dropdown-divider"></div>
          <b-dropdown-item @click="logOut()" href="#!">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </b-dropdown-item>
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>

  <modal :show.sync="showSupportModal" modal-classes="modal-support" style="width: 800px !important; max-width: 900px !important">

    <h6 slot="header" class="modal-title">Support</h6>

    <Support ref="supportlist"/>

      <template slot="footer">

       
        
        <b-button type="button" variant="link" class="ml-auto" @click="showSupportModal = false">Close</b-button>
      </template>
    </modal>
  </div>

</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { BaseNav, Modal } from "@/components";
import { GlobalStore } from "../globalStore";
import Support from "../views/Support.vue";

export default {
  components: {
    CollapseTransition,
    BaseNav,
    Modal,
    Support
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)"
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    appVersion() {
      return GlobalStore.version;
    },
    getNotification() {
      if (GlobalStore.notification) {
        var findNotification = GlobalStore.notification.notificationList.find(f=> f.notification == GlobalStore.notification.currentNotification);
        if (findNotification) { 
          return findNotification 
        } else {
          return {
            notification: "none",
            message: "none"
          }
        }
      } else {
        return {
          notification: "none",
          message: "none"
        }
      }
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      showSupportModal: false
    };
  },
  methods: {
    saveEvent() {

    },
    clickSupport() {
      //this.$router.push("/support");
      this.showSupportModal = true;
      this.$refs.supportlist.expand("answer1")
    },
    getBrokerName() {
      return (
        GlobalStore.brokerObj.Broker_first_name +
        " " +
        GlobalStore.brokerObj.Broker_last_name
      );
    },
    getBrokerInitials() {
      var fullName =
        GlobalStore.brokerObj.Broker_first_name +
        " " +
        GlobalStore.brokerObj.Broker_last_name;
      return fullName
        .split(" ")
        .map(n => n[0])
        .join("");
    },
    logOut() {
      sessionStorage.clear();
      localStorage.removeItem("rmt");
      this.$router.push("/login");
      return false;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    }
  }
};
</script>
<style scoped>
.navbar-top {
  background-color: #98b8cf !important;
}


</style>
